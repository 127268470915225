import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  customerId: any = 'null';
  constructor() {
    if ("customerid" in localStorage) {
      this.customerId = localStorage.getItem('customerid');
    } else {
      this.customerId = this.customerId;
    }
    this.customerId = window.btoa(this.customerId);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add custom headers
    const modifiedReq = req.clone({
        setHeaders: {
          'customerId': this.customerId
        }
      });
    return next.handle(modifiedReq);
  }
}


