export class Configuration {

  // public static serviceAuthorisationURL = "http://localhost:8004"; /*local*/
  // public static serviceAuthorisationURL = "https://turbobusinessserviceauthentication.octanics.co"; /*Turbobusiness*/
  // public static serviceAuthorisationURL = "https://stagingturbobusinessserviceauthentication.octanics.org"; /*Profittrcaker*/
  //public static serviceAuthorisationURL = "https://turbobusinessserviceauthentication.octanics.io"; /*Common Authentication - GG*/
  // public static serviceAuthorisationURL = "https://octanicsindiaoneauthentication.octanics.io"; /*Common Authentication - AWS*/




  // public static serviceCoreURL = "http://localhost:8001"; /*local*/
  // public static serviceIdentityURL="http://localhost:8002";
  public static serviceCoreURL = "https://profittrackerservicecore.octanics.co"; /*local*/
  public static serviceIdentityURL="https://identity.octanics.co";
  // public static serviceCoreURL = "https://devservicecore.octanics.org"; /*dev*/
  // public static serviceCoreURL = "https://uatservicecore.octanics.co/"; /* UAT */
  // public static serviceCoreURL = "https://bethelservicecore.octanics.io"; /*Bethel*/
  // public static serviceCoreURL = "https://alpanaservicecore.octanics.io"; /*Alpana*/
  // public static serviceCoreURL = "https://assistempservicecore.octanics.io"; /*Assistemp*/
  // public static serviceCoreURL = "https://salesservicecore.fusionthought.co"; /*FusionThought*/
  // public static serviceCoreURL = "https://rudraservicecore.fusionthought.net"; /*Rudra*/
  // public static serviceCoreURL = "https://rudraservicecore.octanics.io"; /*Rudra*/
  //  public static serviceCoreURL = "https://sarvadnyaservicecore.octanics.io"; /*Sarvadnya*/
  //public static serviceCoreURL = "https://goswamiservicecore.octanics.io"; /*Goswami*/
  //public static serviceCoreURL = "https://gsmenterpriseservicecore.octanics.io"; /*GSM Enterprise*/
  //public static serviceCoreURL = "https://neweraservicecore.octanics.io"; /*New Era*/
  //public static serviceCoreURL = "https://sampindiaservicecore.octanics.io"; /*Samp India*/
  //public static serviceCoreURL = "https://ayushpaperservicecore.octanics.io"; /*Ayush Papers India*/
  // public static serviceCoreURL = "https://newmaharajaservicecore.octanics.io/"; /*New Maharaja*/
  //public static serviceCoreURL = "https://bonneboucheservicecore.octanics.io/"; /*Bonne Bouche*/
  //public static serviceCoreURL = "https://mandoviservicecore.octanics.io/"; /* Mandovi Furniture*/
  // public static serviceCoreURL = "https://saagarservicecore.octanics.io/"; /*Saagar Enterprise*/
  //public static serviceCoreURL = "https://swastikservicecore.octanics.io/"; /*SWASTIK*/
  //public static serviceCoreURL = "https://colourcurvesservicecore.octanics.io/"; /*Color Curve*/
  // public static serviceCoreURL = "https://packagingservicecore.octanics.io/"; /*Packaging*/
  // public static serviceCoreURL = "https://ambientservicecore.octanics.io"; /*Ambient*/
  // public static serviceCoreURL = "https://saltinmycocoaservicecore.octanics.io"; /*Salt In MyCocoa*/
  // public static serviceCoreURL = "https://yatinindustronicsservicecore.octanics.io"; /*Yatin Industornics*/
  // public static serviceCoreURL = "https://ecopathservicecore.octanics.io"; /*Eco path*/
  // public static serviceCoreURL = "https://coolwaysservicecore.octanics.io"; /*Coolways*/
  // public static serviceCoreURL = "https://airtonicsservicecore.octanics.io"; /*Airtonics*/
  // public static serviceCoreURL = "https://sizezeropaanipuriservicecore.octanics.io"; /*Size Zero*/
  // public static serviceCoreURL = "https://stellarrubberrollersservicecore.octanics.io/"; /*StellarRubberRollers*/
  // public static serviceCoreURL = "https://priyapumpsservicecore.octanics.io/"; /*priyaPumps*/
  //public static serviceCoreURL = "https://turbobusinessservicecore.octanics.co"; /*Turbobusiness*/
  //public static serviceCoreURL = "https://turbobusinessservicecore.fusionthought.co"; /*Temp*/
  //public static serviceCoreURL = "https://salesservicecore.octanics.co"; /*Sales*/
  // public static serviceCoreURL = "https://trainingservicecore.octanics.co"; /*Training*/
  // public static serviceCoreURL = "https://profittrackerservicecore.octanics.org"; /*Profittracker*/
  // public static serviceCoreURL = "https://graceglobalsdnbhdservicecore.octanics.io"; /*GraceGlobals*/

}
