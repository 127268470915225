import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {map} from "rxjs/internal/operators";

@Injectable()
export class Authorisation {
  constructor(private http: Http, private router: Router, private routes: ActivatedRoute) {
  }

  roleId: string;
  permission: string;
  business_id: any;

  getAuthorized(event) {
    this.roleId = localStorage.getItem('roleid');
    this.permission='VIEW';
    this.business_id=localStorage.getItem('businessid');
    const getauthorized={
      route:event.url,
      role_id:this.roleId,
      permission:this.permission,
      business_id:this.business_id
    }
    return this.http.post(Configuration.serviceCoreURL + '/getauthorized',getauthorized)
      .pipe(map(result => result.json()));
  }

  canActivate(route: ActivatedRouteSnapshot, routes: ActivatedRoute) {
    return this.getAuthorized(routes).pipe(map((auth) => {
      if (auth) {
        return true;
      }
      this.router.navigateByUrl('/noaccesspage');
      return false;
    }));
  }
}
